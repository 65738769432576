import { Component, input, signal } from '@angular/core';
import { type IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { FlyIconModule, type Icon, Icons } from '@garmin-avcloud/avcloud-ui-common/icon';

@Component({
  selector: 'pilot-nav-link',
  imports: [FlyIconModule, RouterLink, RouterLinkActive],
  templateUrl: './nav-link.component.html',
  styleUrl: './nav-link.component.scss',
})
export class NavLinkComponent {
  text = input.required<string>();
  primary = input(true);
  path = input.required<string>();
  icon = input.required<Icon>();
  iconOnly = input(false);
  isActiveOptions = input<IsActiveMatchOptions | { exact: boolean }>({ exact: false });

  readonly locked = signal(false);

  readonly Icons = Icons;
}
