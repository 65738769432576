import {
  DestroyRef,
  Directive,
  HostListener,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthService, SubscriptionService } from '@garmin-avcloud/avcloud-web-utils';
import { NavLinkComponent } from '@shared/components/nav-link/nav-link.component';
import { SubscriptionRequiredModalComponent } from '@shared/components/subscription-required-modal/subscription-required-modal.component';
import { UnavailableFeatureModalComponent } from '@shared/components/unavailable-feature-modal/unavailable-feature-modal.component';
import { BasicModalService } from '@shared/services/basic-modal/basic-modal.service';
import { FlightOrchestratorFlagsService } from '@shared/services/flight-orchestrator-flags/flight-orchestrator-flags.service';

/**
 * A directive that adds "lock" functionality to a {@link NavLinkComponent}.
 *
 * Basic unlock criteria:
 * - The user is authenticated (logged in)
 * - The user has a GP subscription
 *
 * Aircraft (temporary) unlock criteria:
 * - The user's data is being dual-synced
 *
 * Flights (temporary) unlock criteria:
 * - The user's data is being synced only into flight domain
 *
 * @example
 * ```html
 * <pilot-nav-link
 *   pilotNavLinkLock
 *   featureType="flights"
 *   lockedModalTitle="Flights Page Unavailable"
 *   text="Flights"
 *   path="/flights"
 *   [icon]="FlightsIcon"></pilot-nav-link>
 * ```
 */
@Directive({
  selector: '[pilotNavLinkLock]',
  standalone: true,
})
export class NavLinkLockDirective {
  private readonly authService = inject(AuthService);
  private readonly basicModalService = inject(BasicModalService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly flightOrchestratorFlagsService = inject(FlightOrchestratorFlagsService);
  private readonly isAuthenticated = toSignal(this.authService.isAuthenticated());
  private readonly subscriptionService = inject(SubscriptionService);

  private readonly navLink = inject(NavLinkComponent);

  featureType = input.required<'aircraft' | 'flights'>();
  lockedModalTitle = input('Feature Unavailable');

  private readonly hasGpSubscription = signal(false);
  private readonly featureUnlocked = computed(() =>
    this.featureType() === 'aircraft'
      ? this.flightOrchestratorFlagsService.aircraftFeaturesUnlocked()
      : this.flightOrchestratorFlagsService.flightsFeaturesUnlocked(),
  );

  constructor() {
    this.navLink.locked.set(true);

    // Reload orchestrator flags on user login
    effect(() => {
      if (!(this.isAuthenticated() ?? false)) {
        return;
      }
      this.flightOrchestratorFlagsService.load().subscribe();
      untracked(() => {
        this.subscriptionService
          .hasFeatureTypes('FULLAPP')
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((hasSubscription) => this.hasGpSubscription.set(hasSubscription));
      });
    });

    effect(() => {
      const hasSub = this.hasGpSubscription();
      const unlocked = this.featureUnlocked();
      untracked(() => this.navLink.locked.set(!hasSub || !unlocked));
    });
  }

  @HostListener('click')
  onClick(): void {
    if (!this.hasGpSubscription()) {
      this.basicModalService.show(SubscriptionRequiredModalComponent);
    } else if (!this.featureUnlocked()) {
      this.basicModalService.showWithTitle(
        UnavailableFeatureModalComponent,
        this.lockedModalTitle(),
      );
    }
  }
}
